
<template>
    <div>
      <highcharts :options="chartOptions"></highcharts>
    </div>
  </template>
        
  <script>
  import { Chart } from "highcharts-vue";
  import axios from "axios";
  export default {
    name: "GraficaReportePostulacion",
    components: {
      highcharts: Chart,
    },
  
    data() {
      return {
        chartOptions: {
          colors: [
            "#ff1c79",
            "#001871",
            "#ba4477",
            "#4b3074",
            "#c84778",
            "#DB843D",
            "#828282",
            "#444444",
            "#000000",
          ],
          xAxis: {
            categories: [],
          },
  
          yAxis: {
            title: {
              text: "Cantidad",
            },
          },
  
          exporting: {
            enabled: true,
          },
  
          title: {
            text: "Postulación por transportadora",
            align: "center",
            size: 5,
          },
  
          plotOptions: {
            column: {
              stacking: "normal",
            },
          },
  
          series: [
            {
              type: "column",
              data: [],
              marker: {
                lineWidth: 3,
                fillColor: "white",
              },
              stack: "male",
            },
            {
              type: "column",
              data: [],
              marker: {
                lineWidth: 3,
                fillColor: "white",
              },
              stack: "male",
            },
            /*  {
              type: "spline",
              name: "Promedio NSV",
              data: [],
              lineColor: Highcharts.getOptions().colors[4],
              color: "black",
              marker: {
                lineWidth: 1,
                lineColor: Highcharts.getOptions().colors[3],
                fillColor: "white",
              },
            },
            {
              type: "spline",
              name: "Promedio GSV",
              data: [],
              lineColor: Highcharts.getOptions().colors[3],
              color: "black",
              marker: {
                lineWidth: 1,
                lineColor: Highcharts.getOptions().colors[4],
                fillColor: "white",
              },
            }, */
          ],
          credits: {
            enabled: false,
          },
        },
        respuesta: [],
      };
    },
  
    methods: {
      async graficaPostulacion(resp) {
        let transportadoras = [
        ];
        let postulado = [];
        let noPostulado = [];  
        for (let i = 0; i < resp.length; i++) {
            transportadoras.push(resp[i].empresa);
            postulado.push(parseFloat(resp[i].postulado));
            noPostulado.push(parseFloat(resp[i].noPostulado));

        }
        this.chartOptions.xAxis.categories = transportadoras;
        this.chartOptions.series[0].data = noPostulado;
        this.chartOptions.series[1].data = postulado;
  
        this.chartOptions.series[0].name = "NO POSTULADO";
        this.chartOptions.series[1].name = "POSTULADO";
      },
    },
  };
  </script>
  